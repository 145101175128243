import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M19.1996 3H4.79958C4.32377 3.002 3.86816 3.19254 3.53257 3.52986C3.19699 3.86718 3.00881 4.32377 3.00926 4.79958L3 21L6.6 17.4H19.1996C19.6765 17.3987 20.1336 17.2087 20.4709 16.8715C20.8082 16.5343 20.9984 16.0774 21 15.6004V4.79958C20.9984 4.32263 20.8082 3.86568 20.4709 3.5285C20.1336 3.19133 19.6765 3.00133 19.1996 3ZM6.6 9.30021H17.4V11.0998H6.6V9.30021ZM13.8 13.8H6.6V11.9996H13.8V13.8ZM17.4 8.40042H6.6V6.6H17.4V8.40042Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'ChatIcon'

export const ChatIcon = memo<IconProps>(themed(Icon))
