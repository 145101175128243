import { create } from 'zustand'
import API from 'app/api'
import { upsertUsers } from 'app/features/chat/helpers'

// Retaining api response schema for the store
interface UserInfoStore {
  isLoading: boolean
  error: object | boolean
  ready: boolean
  id: number
  username: string
  nickname: string
  email_status: string
  account_status: string
  show_verified_badge: boolean
  profile_status: string
  profile_type: string
  gender: string
  location: string
  account_role: string
  permissions: {
    browse: boolean
    chat: boolean
    post: boolean
    react: boolean
    send_dm: boolean
    moderate: boolean
  }
  avatar: {
    sm: string
    md: string
    lg: string
  }
  setUserInfo: () => Promise<void>
  setAvatar: (image: string) => Promise<object>
}

const defaultProps = {
  isLoading: false,
  error: false,
  ready: false,
  id: false,
  username: '',
  nickname: '',
  email_status: '',
  account_status: '',
  show_verified_badge: false,
  profile_status: '',
  profile_type: '',
  gender: '',
  location: '',
  account_role: '',
  permissions: {
    browse: false,
    chat: false,
    post: false,
    react: false,
    send_dm: false,
    moderate: false,
  },
  avatar: {
    sm: '',
    md: '',
    lg: '',
  },
}

export const useUserInfoStore = create<UserInfoStore>((set) => ({
  ...defaultProps,
  setUserInfo: async () => {
    set({ isLoading: true, error: false })
    try {
      const userInfoResponse = await API.auth.getCurrentUserInfo()
      set({ ...userInfoResponse.user, error: false, isLoading: false, ready: true })
      // TEMP: REMOVE AFTER API IMPLEMENTATION
      await upsertUsers([userInfoResponse.user])
      return userInfoResponse.user
    } catch (error) {
      set({ error, isLoading: false })
      return { error }
    }
  },
  setAvatar: async (image) => {
    try {
      const response = await API.profile.setAvatar(image)
      set({ avatar: response.avatar })
      return response
    } catch (error) {
      return { error }
    }
  },
  clear: () => {
    set({ ...defaultProps })
  },
}))
