import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M16.6941 14.9319C17.9035 13.2787 18.4443 11.2299 18.2085 9.19512C17.9726 7.16036 16.9775 5.28958 15.4221 3.95675C13.8666 2.62392 11.8655 1.92725 9.8186 2.00602C7.77174 2.08478 5.83002 2.93318 4.3816 4.3816C2.93318 5.83002 2.08478 7.77174 2.00602 9.8186C1.92725 11.8655 2.62392 13.8666 3.95675 15.4221C5.28958 16.9775 7.16036 17.9726 9.19512 18.2085C11.2299 18.4443 13.2787 17.9035 14.9319 16.6941L20.2388 21.999L22 20.2399L16.6921 14.9319H16.6941ZM10.1602 15.7703C9.05085 15.7703 7.96639 15.4413 7.04399 14.825C6.12158 14.2087 5.40265 13.3327 4.97811 12.3077C4.55358 11.2828 4.4425 10.155 4.65893 9.06696C4.87535 7.97891 5.40956 6.97947 6.19401 6.19502C6.97845 5.41058 7.97789 4.87637 9.06594 4.65994C10.154 4.44352 11.2818 4.55459 12.3067 4.97913C13.3316 5.40367 14.2077 6.1226 14.824 7.045C15.4403 7.96741 15.7693 9.05187 15.7693 10.1612C15.7677 11.6484 15.1762 13.0741 14.1246 14.1257C13.0731 15.1772 11.6473 15.7687 10.1602 15.7703Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'SearchIcon'

export const SearchIcon = memo<IconProps>(themed(Icon))
