/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import { useAuth } from 'app/lib/auth'
import { useLink } from 'solito/link'
import {
  DelayedTextField,
  Button,
  XStack,
  SearchIcon,
  EmailIcon,
  NotificationsIcon,
  useMedia,
} from '@bp/ui'
import Logo from './logo'
import { useUserInfoStore } from 'app/store'
import { useEffect, useState } from 'react'
import Avatar from './avatar'

export default function Header({ hideHeader }) {
  const { user, error, isLoading, login } = useAuth()
  const media = useMedia()
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo)
  const ready = useUserInfoStore((state) => state.ready)
  const isLoadingUserInfo = useUserInfoStore((state) => state.isLoading)

  const messagesProps = useLink({
    href: '/messages',
  })

  const designProps = useLink({
    href: '/design',
  })

  useEffect(() => {
    if (user && !isLoadingUserInfo && !ready) {
      setUserInfo()
    }
  }, [ready, isLoadingUserInfo, user])

  if (hideHeader) {
    return null
  }

  return (
    <XStack
      height="72px"
      $gtLg={{
        mb: '$7',
      }}
    >
      <XStack
        position="fixed"
        height="72px"
        elevation={3}
        width="100%"
        alignItems="center"
        backgroundColor="$lightest"
        zIndex="999"
        justifyContent="space-between"
        px="$4"
        gap="$6"
      >
        <XStack>
          <Logo scale={0.75} />
        </XStack>
        <XStack $xxs={{ display: 'none' }} flexShrink={1} flexGrow={1}>
          <DelayedTextField
            flexShrink={1}
            flexGrow={1}
            placeholder="Search people, posts, trending and more"
            accessibilityLabel="Search"
            onChangeText={() => {}}
            startIcon={<SearchIcon />}
          />
        </XStack>

        {isLoading || error || !user ? (
          <XStack gap="$2">
            <Button variant="primary" onPress={() => login(false)}>
              Login
            </Button>
            <Button variant="accent" onPress={login}>
              Signup
            </Button>
          </XStack>
        ) : (
          <XStack gap="$4">
            {media.xxs && (
              <Button
                variant="text"
                {...messagesProps}
                icon={<SearchIcon size={24} />}
                $gtXss={{ display: 'none' }}
              />
            )}

            <Button variant="text" {...messagesProps} icon={<EmailIcon size={24} />} />

            <Button variant="text" onPress={() => {}} icon={<NotificationsIcon size={24} />} />
            <Avatar />
          </XStack>
        )}
      </XStack>
    </XStack>
  )
}
