import { useState, useEffect } from 'react'
import { useRouter } from 'solito/router'

import { AUTH0_NAMESPACE, ONBOARDING } from '../../utils/constants'
import API from 'app/api'

export const usePostLogin = ({ user, accessToken }) => {
  const { push } = useRouter()
  const [status, setStatus] = useState(ONBOARDING.loading)

  useEffect(() => {
    if (user) {
      const isOnboardingPending = user[`${AUTH0_NAMESPACE}/status`] === '3'
      setStatus(!!isOnboardingPending ? ONBOARDING.pending : ONBOARDING.complete)
    }
  }, [user])

  useEffect(() => {
    if (!accessToken) {
      return
    }

    API.auth.setLastLogin()
  }, [accessToken])

  useEffect(() => {
    if (status === ONBOARDING.pending) {
      push('/register')
    }
  }, [status])

  useEffect(() => {
    if (status === ONBOARDING.processed) {
      API.account.completeOnboarding()
    }
  }, [status])

  return { status, setStatus }
}
