import { useEffect } from 'react'
import { useStream } from './useStream'
import { useUserInfoStore } from 'app/store'
import { useStreamPushNotification } from './useStreamPushNotification'

export function StreamConnect({ children }) {
  const userInfoReady = useUserInfoStore((s) => s.ready)
  const connect = useStream((s) => s.connect)
  const disconnect = useStream((s) => s.disconnect)
  const chatConnected = useStream((s) => s.chatConnected)
  const chatConnecting = useStream((s) => s.chatConnecting)
  const videoConnected = useStream((s) => s.chatConnected)
  const videoConnecting = useStream((s) => s.videoConnecting)
  const disconnected = useStream((s) => s.disconnected)
  // Connect to GS chat and video/audio client
  const connecting = chatConnecting || videoConnecting
  const connected = chatConnected && videoConnected

  // Notification permission and device registration
  useStreamPushNotification()

  useEffect(() => {
    const proceed = userInfoReady && !connected && !connecting && !disconnected

    if (!proceed) {
      return
    }

    connect()

    return async () => {
      if (!connected && !connecting) {
        await disconnect()
      }
    }
  }, [connecting, connected, disconnected, userInfoReady])

  return <>{children}</>
}
