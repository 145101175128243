import { createContext, useRef } from 'react'

import store from './store'

export const StreamContext = createContext({})

export const StreamProvider = ({ children }) => {
  const streamStore = useRef(null)

  if (!streamStore.current) {
    streamStore.current = store
  }

  return <StreamContext.Provider value={streamStore.current}>{children}</StreamContext.Provider>
}
