// TODO: Revisit implementation
import { Path, Svg } from 'react-native-svg'

export const Logo = ({ scale = 1 }) => (
  <Svg width={59 * scale} height={46 * scale} viewBox="0 0 59 46" fill="none">
    <Path
      d="M58.0907 17.614C57.5846 15.0991 52.8829 13.7373 45.9583 13.6552C46.0784 12.9624 46.1386 12.2602 46.138 11.5567C46.139 0.499987 35.6247 0.5 27.5182 0.5H14.1561V19.4044C5.31086 22.6276 -0.360051 26.553 0.24986 29.5908C0.797882 32.3154 6.26815 33.6853 14.1561 33.5425V33.5536C20.0982 33.4726 26.0181 32.8017 31.8315 31.5504C33.3861 31.2282 34.9024 30.88 36.3804 30.5057C33.5205 31.6315 30.4959 32.7076 27.3485 33.7066C22.7786 35.1556 18.3326 36.3198 14.1561 37.1912V45.5H29.2292C37.7779 45.5 48.4808 45.5 48.4808 33.0946C48.501 30.876 47.9246 28.6938 46.8137 26.783C54.1307 23.7746 58.6367 20.3387 58.0907 17.614ZM14.1561 31.6203C9.3237 31.3964 6.11241 30.3142 5.73708 28.4539C5.31184 26.3362 8.6519 23.6916 14.1561 21.3256V31.6203ZM45.6927 25.1952C44.0037 23.2119 41.7458 21.8146 39.2353 21.1989C40.6226 20.839 41.9057 20.1489 42.9782 19.1858C44.0508 18.2227 44.882 17.0143 45.4033 15.6604C49.5499 15.9999 52.26 17.0506 52.6024 18.7499C52.9867 20.6639 50.2875 23.0167 45.6927 25.1952V25.1952Z"
      fill="#4AE3A8"
    />
  </Svg>
)

export const Logotype = () => (
  <Svg width="180" height="23" viewBox="0 0 180 23" fill="none">
    <Path
      d="M15.7698 11.5395C16.6237 11.1065 17.339 10.4363 17.8325 9.60695C18.3261 8.77755 18.5775 7.82299 18.5577 6.85425C18.5577 3.53784 15.8626 1.31674 10.615 1.31674H0.365234V22.6115H11.2129C16.728 22.6115 19.6069 20.4837 19.6069 16.8015C19.6473 15.6133 19.2899 14.4462 18.5931 13.4907C17.8963 12.5351 16.9011 11.8473 15.7698 11.5395V11.5395ZM5.1886 5.02835H10.013C12.3808 5.02835 13.6715 5.85009 13.6715 7.52501C13.6715 9.19994 12.3808 10.0501 10.013 10.0501H5.1886V5.02835ZM10.8535 18.9009H5.1886V13.639H10.8535C13.371 13.639 14.7187 14.4912 14.7187 16.2857C14.7197 18.1116 13.372 18.9009 10.8545 18.9009H10.8535Z"
      fill="#252525"
    />
    <Path
      d="M29.1668 18.7793C28.755 18.979 28.3045 19.0828 27.8481 19.0833C27.568 19.108 27.286 19.0682 27.0231 18.9668C26.7603 18.8653 26.5234 18.7049 26.3303 18.4974C26.1371 18.29 25.9927 18.0409 25.9077 17.7688C25.8228 17.4967 25.7996 17.2086 25.8397 16.9261V0.039032H21.1641V17.1976C21.1641 20.8453 23.3222 22.8567 26.9477 22.8567C27.7729 22.8598 28.593 22.7256 29.3754 22.4595L29.1668 18.7793Z"
      fill="#252525"
    />
    <Path
      d="M42.4768 6.24423V7.97898C41.8615 7.31378 41.1111 6.79232 40.2776 6.4508C39.444 6.10927 38.5472 5.95581 37.6494 6.00106C33.0966 6.00106 29.5898 9.2871 29.5898 14.4304C29.5898 19.5737 33.0966 22.8567 37.6494 22.8567C38.5932 22.9148 39.5376 22.7533 40.4106 22.3846C41.2835 22.0158 42.062 21.4495 42.6865 20.7289V22.6135H47.1525V6.24423H42.4768ZM38.457 18.9617C36.1192 18.9617 34.3224 17.2594 34.3224 14.4314C34.3224 11.6034 36.1192 9.89808 38.457 9.89808C40.7639 9.89808 42.5637 11.6004 42.5637 14.4314C42.5637 17.2625 40.7649 18.9617 38.457 18.9617Z"
      fill="#252525"
    />
    <Path
      d="M57.4307 18.9617C56.2883 18.8991 55.2129 18.3944 54.426 17.5514C53.6391 16.7084 53.2005 15.5913 53.2005 14.4299C53.2005 13.2686 53.6391 12.1514 54.426 11.3084C55.2129 10.4653 56.2883 9.9606 57.4307 9.89805C58.1818 9.89162 58.9196 10.0988 59.5605 10.4962C60.2015 10.8936 60.72 11.4653 61.0572 12.1465L64.6828 10.1706C63.5139 7.49356 60.8477 6.00104 57.4607 6.00104C52.2151 6.00104 48.4688 9.49986 48.4688 14.4304C48.4688 19.3609 52.2141 22.8567 57.4607 22.8567C60.8477 22.8567 63.5139 21.3368 64.6828 18.7185L61.0572 16.7102C60.7256 17.3962 60.2085 17.9721 59.5663 18.3708C58.9241 18.7695 58.1834 18.9745 57.4307 18.9617V18.9617Z"
      fill="#252525"
    />
    <Path
      d="M76.4301 13.181L83.1432 6.24421H77.5681L70.7343 12.8163V0.039032H66.0586V22.6115H70.7343V18.4449L72.9823 16.1935L78.0792 22.6115H83.743L76.4301 13.181Z"
      fill="#252525"
    />
    <Path
      d="M95.2517 1.31674H86.168V22.6115H91.0223V16.7407H95.2467C100.852 16.7407 104.357 13.791 104.357 9.04491C104.363 4.26839 100.857 1.31674 95.2517 1.31674ZM94.9822 12.7261H91.0273V5.32928H94.9822C97.9509 5.32928 99.4482 6.69816 99.4482 9.04082C99.4482 11.3571 97.9509 12.7261 94.9832 12.7261H94.9822Z"
      fill="#252525"
    />
    <Path
      d="M113.984 18.7793C113.573 18.9789 113.123 19.0827 112.666 19.0833C112.386 19.1082 112.104 19.0685 111.841 18.9671C111.578 18.8657 111.341 18.7053 111.148 18.4978C110.955 18.2903 110.811 18.0412 110.726 17.769C110.641 17.4968 110.618 17.2086 110.658 16.9261V0.039032H105.982V17.1976C105.982 20.8453 108.141 22.8567 111.767 22.8567C112.592 22.8596 113.412 22.7255 114.195 22.4595L113.984 18.7793Z"
      fill="#252525"
    />
    <Path
      d="M127.289 6.24424V7.97898C126.674 7.31397 125.925 6.79264 125.092 6.45111C124.258 6.10958 123.362 5.95599 122.465 6.00106C117.909 6.00106 114.402 9.28711 114.402 14.4304C114.402 19.5737 117.908 22.8567 122.465 22.8567C123.408 22.9147 124.353 22.7531 125.225 22.3843C126.098 22.0156 126.877 21.4494 127.501 20.7289V22.6135H131.964V6.24424H127.289ZM123.274 18.9617C120.937 18.9617 119.138 17.2594 119.138 14.4314C119.138 11.6034 120.935 9.89808 123.274 9.89808C125.582 9.89808 127.379 11.6004 127.379 14.4314C127.379 17.2625 125.582 18.9617 123.274 18.9617Z"
      fill="#252525"
    />
    <Path
      d="M143.686 6.00107C142.697 5.96163 141.712 6.13357 140.792 6.50573C139.873 6.87788 139.041 7.44195 138.351 8.16137V6.24425H133.885V22.6094H138.56V14.5196C138.56 11.5072 140.178 10.1079 142.426 10.1079C144.494 10.1079 145.693 11.3238 145.693 13.9714V22.6094H150.367V13.2399C150.367 8.25259 147.491 6.00107 143.686 6.00107Z"
      fill="#252525"
    />
    <Path
      d="M161.098 19.0519C159.001 19.0519 157.504 18.2322 156.811 16.6201L168.739 14.2774C168.709 9.04592 164.963 6.00308 160.408 6.00308C155.434 6.00308 151.777 9.53231 151.777 14.4324C151.777 19.2961 155.404 22.8587 160.979 22.8587C162.211 22.938 163.445 22.7418 164.594 22.2842C165.744 21.8265 166.78 21.1185 167.63 20.21L165.143 17.4742C164.61 18.0032 163.976 18.4161 163.281 18.6874C162.585 18.9587 161.842 19.0827 161.098 19.0519ZM160.408 9.59106C161.262 9.54477 162.106 9.79767 162.799 10.3075C163.492 10.8173 163.991 11.553 164.213 12.3917L156.363 13.942C156.453 11.1748 158.073 9.59106 160.408 9.59106V9.59106Z"
      fill="#252525"
    />
    <Path
      d="M178.571 18.4743C178.009 18.8737 177.338 19.0861 176.652 19.0823C176.374 19.1036 176.095 19.0612 175.835 18.958C175.576 18.8549 175.342 18.6937 175.153 18.4865C174.963 18.2793 174.821 18.0313 174.739 17.761C174.656 17.4906 174.635 17.205 174.676 16.9251V10.2619H178.661V6.61408H174.676V2.62689H169.998V16.9879C169.998 20.8808 172.183 22.8597 176.021 22.8597C177.354 22.9062 178.669 22.5459 179.798 21.8252L178.571 18.4743Z"
      fill="#252525"
    />
  </Svg>
)
