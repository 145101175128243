import { TamaguiProvider, TamaguiProviderProps, ToastProvider, ToastViewport } from '@bp/ui'
import { useColorScheme } from 'react-native'
import config from '../tamagui.config'
import { Auth0Provider } from 'app/lib/auth'
import React from 'react'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { StreamProvider } from 'app/lib/stream/context'
import { StreamConnect } from 'app/lib/stream/connect'

export function Provider({ children, ...rest }: Omit<TamaguiProviderProps, 'config'>) {
  const scheme = useColorScheme()
  return (
    <TamaguiProvider
      config={config}
      disableInjectCSS
      defaultTheme={scheme === 'dark' ? 'dark' : 'light'}
      {...rest}
    >
      <Auth0Provider>
        <StreamProvider>
          <SafeAreaProvider>
            <StreamConnect>
              <ToastProvider>
                {children}
                <ToastViewport flexDirection="column" bottom={100} left={0} right={0} />
              </ToastProvider>
            </StreamConnect>
          </SafeAreaProvider>
        </StreamProvider>
      </Auth0Provider>
    </TamaguiProvider>
  )
}
