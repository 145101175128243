import { XStack, YStack, useMedia, Spinner } from '@bp/ui'
import Navigation from './navigation'
import Aside from './aside'
import React from 'react'
import { useAuth } from 'app/lib/auth'

export default function Main({ children, hideAside = false, hideNavigation = false }) {
  const media = useMedia()
  const { user, isLoading } = useAuth()

  if (!user && !isLoading) {
    return (
      <YStack flexGrow={1} flexShrink={1}>
        {children}
      </YStack>
    )
  }

  return (
    <>
      {media.gtLg ? (
        <XStack flexGrow={1} flexShrink={1}>
          {!hideNavigation ? (
            <YStack width={'280px'} padding="10px">
              <Navigation />
            </YStack>
          ) : null}

          <XStack flexGrow={1} flexShrink={1}>
            <YStack flexGrow={1} flexShrink={1}>
              {children}
            </YStack>
            {!hideAside ? (
              <YStack width={'322px'} padding="10px">
                <Aside />
              </YStack>
            ) : null}
          </XStack>
        </XStack>
      ) : (
        <YStack flexGrow={1}>
          {!hideNavigation ? (
            <YStack
              alignItems="center"
              justifyContent="center"
              height="84px"
              $gtSm={{
                mb: '$7',
              }}
            >
              <Navigation />
            </YStack>
          ) : null}

          <XStack flexGrow={1} flexShrink={1}>
            <YStack flexGrow={1} flexShrink={1}>
              {children}
            </YStack>
            {media.gtMd && !hideAside ? (
              <YStack width={'310px'} padding="10px">
                <Aside />
              </YStack>
            ) : null}
          </XStack>
        </YStack>
      )}
    </>
  )
}
