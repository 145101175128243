/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */

import { Logo as BPLogo, Button } from '@bp/ui'
import { useLink } from 'solito/link'
import React from 'react'

export default function Logo() {
  const logoProps = useLink({
    href: '/',
  })

  return (
    <Button variant="link" {...logoProps}>
      <BPLogo scale={0.75} />
    </Button>
  )
}
