export const upsertUsers = async (users) => {
  try {
    // upsert new users
    const usersToUpsert = users.map((user) => ({
      id: user.id.toString(),
      avatar: {
        md: user.avatar.md,
        lg: user.avatar.lg,
      },
      nickname: user.nickname,
      username: user.username,
    }))

    await fetch(`${process.env.BP_PUBLIC_HOST_URL}/api/gs/upsert`, {
      body: JSON.stringify({
        users: [...usersToUpsert],
      }),
      method: 'POST',
    })
  } catch (error) {
    console.log('Error upserting users', error)
  }
}
