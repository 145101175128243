import { Button as TuiButton, styled } from 'tamagui'

export const Button = styled(TuiButton, {
  name: 'Button',
  fontWeight: '500',
  backgroundColor: '$backgroundTransparent',
  hoverStyle: {
    backgroundColor: '$backgroundTransparent',
    borderColor: '$backgroundTransparent',
  },
  pressStyle: {
    backgroundColor: '$backgroundTransparent',
    borderColor: '$backgroundTransparent',
  },
  focusStyle: {
    outlineColor: '$secondaryLight',
  },
  variants: {
    variant: {
      primary: {
        backgroundColor: '$secondaryDarker',
        color: '$lighter',
        hoverStyle: {
          backgroundColor: '$darker',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$darker',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$lightest',
          outlineColor: '$secondaryLight',
        },
      },
      accent: {
        backgroundColor: '$primaryDark',
        color: '$darker',
        hoverStyle: {
          backgroundColor: '$primaryDarker',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$primaryDarker',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$lightest',
          outlineColor: '$primaryDark',
        },
      },
      secondary: {
        backgroundColor: '$light',
        color: '$darker',
        hoverStyle: {
          backgroundColor: '$dark',
          color: '$light',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$dark',
          color: '$light',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          outlineColor: '$light',
          borderColor: '$lightest',
        },
      },
      tertiary: {
        backgroundColor: '$backgroundTransparent',
        paddingLeft: 0,
        paddingRight: 0,
        color: '$darkest',
        borderBottomColor: '$primaryDark',
        borderRadius: 0,
        borderWidth: 0,
        //borderBottomWidth: 3, //TODO: Use token
        hoverStyle: {
          color: '$primaryDarker',
          borderBottomColor: '$primaryDarker',
          backgroundColor: '$backgroundTransparent',
        },
        pressStyle: {
          color: '$primaryDarker',
          borderBottomColor: '$primaryDarker',
          backgroundColor: '$backgroundTransparent',
        },
        focusStyle: {
          color: '$darkest',
          outlineColor: '$primaryLight',
        },
      },
      outlined: {
        borderColor: '$secondaryDark',
        backgroundColor: '$lightest',
        color: '$secondaryDarker',
        borderWidth: 1,
        hoverStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
          color: '$secondaryDark',
        },
        pressStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
        },
        focusStyle: {
          borderColor: '$secondaryDark',
          backgroundColor: '$lightest',
        },
      },
      link: {
        backgroundColor: '$backgroundTransparent',
        color: '$secondaryDark',
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: '600',
      },
      text: {
        backgroundColor: '$backgroundTransparent',
        color: '$color',
        padding: 0,
        height: 'auto',
      },
      danger: {
        backgroundColor: '$errorDarker',
        color: '$lightest',
        hoverStyle: {
          backgroundColor: '$errorDark',
          borderColor: '$backgroundTransparent',
        },
        pressStyle: {
          backgroundColor: '$errorDark',
          borderColor: '$backgroundTransparent',
        },
        focusStyle: {
          borderColor: '$errorLighter',
          outlineColor: '$errorDarker',
        },
      },
    },
    selected: {
      true: {
        backgroundColor: '$secondaryDarker',
        active: {
          true: {
            backgroundColor: 'red',
          },
        },
      },
    },

    disabled: {
      true: {
        backgroundColor: '$lighter',
        color: '$medium',
        hoverStyle: {
          backgroundColor: '$lighter',
          color: '$medium',
        },
        focusStyle: {
          backgroundColor: '$lighter',
          color: '$medium',
        },
        pressStyle: {
          backgroundColor: '$lighter',
          color: '$medium',
        },
      },
    },
  },
  defaultVariants: {
    size: '$4',
  },
})
