import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M12.2051 22C13.3385 22 14.2564 21.0821 14.2564 19.9487H10.1538C10.1538 21.0821 11.0718 22 12.2051 22ZM18.359 15.8462V10.7179C18.359 7.5641 16.6821 4.93333 13.7436 4.2359V3.53846C13.7436 2.68718 13.0564 2 12.2051 2C11.3538 2 10.6667 2.68718 10.6667 3.53846V4.2359C7.72821 4.93333 6.05128 7.5641 6.05128 10.7179V15.8462L4 17.8974V18.9231H20.4103V17.8974L18.359 15.8462Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'NotificationsIcon'

export const NotificationsIcon = memo<IconProps>(themed(Icon))
