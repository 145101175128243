import { Button, Avatar as BPAvatar, Popover, PopoverProps, Adapt, XStack, YStack } from '@bp/ui'
import { useLink } from 'solito/link'
import { useUserInfoStore } from 'app/store'
import { useAuth } from 'app/lib/auth'
import { useEffect } from 'react'

/**
 *
 * Note: This implementation is incomplete and will be revisited as part of different ticket for header. Refer BLAC-742
 *
 */
export default function Avatar() {
  const setUserInfo = useUserInfoStore((state) => state.setUserInfo)
  const ready = useUserInfoStore((state) => state.ready)
  const avatar = useUserInfoStore((state) => state.avatar)
  const isLoadingUserInfo = useUserInfoStore((state) => state.isLoading)
  const { user } = useAuth()

  const settingsProps = useLink({
    href: '/settings',
  })
  const logoutProps = useLink({
    href: '/logout',
  })
  const designProps = useLink({
    href: '/design',
  })

  useEffect(() => {
    if (user && !isLoadingUserInfo && !ready) {
      setUserInfo()
    }
  }, [ready, isLoadingUserInfo])

  return (
    <CustomPopover
      placement="right-end"
      Icon={avatar?.md ? () => <BPAvatar circular size="$4" src={avatar?.md} /> : () => null}
      Content={() => (
        <YStack>
          <Button variant="plain" width={250} {...logoutProps}>
            Logout
          </Button>
          <Button variant="plain" width={250} {...settingsProps}>
            Settings
          </Button>
          <Button variant="plain" width={250} {...designProps}>
            Design
          </Button>
        </YStack>
      )}
      Name="menu-popover"
    />
  )
}

function CustomPopover({
  Icon,
  Name,
  Content,
  ...props
}: PopoverProps & { Icon?: any; Name?: string; Content?: any }) {
  return (
    <Popover size="$4" allowFlip {...props}>
      <Popover.Trigger asChild>
        <Button variant="link">
          <Icon />
        </Button>
      </Popover.Trigger>
      <Adapt when="sm" platform="touch">
        <Popover.Sheet modal dismissOnSnapToBottom>
          <Popover.Sheet.Frame padding="$4">
            <Adapt.Contents />
          </Popover.Sheet.Frame>

          <Popover.Sheet.Overlay
            animation="lazy"
            enterStyle={{ opacity: 0 }}
            exitStyle={{ opacity: 0 }}
          />
        </Popover.Sheet>
      </Adapt>
      <Popover.Content
        borderWidth={1}
        borderColor="$borderColor"
        enterStyle={{ y: -10, opacity: 0 }}
        exitStyle={{ y: -10, opacity: 0 }}
        elevate
        animation={[
          'quick',
          {
            opacity: {
              overshootClamping: true,
            },
          },
        ]}
      >
        <Popover.Arrow borderWidth={1} borderColor="$borderColor" />
        <YStack gap="$3">
          <XStack gap="$3">
            <Content />
          </XStack>
          <Popover.Close asChild>
            <Button
              size="$3"
              onPress={() => {
                /* Custom code goes here, does not interfere with popover closure */
              }}
            >
              Close
            </Button>
          </Popover.Close>
        </YStack>
      </Popover.Content>
    </Popover>
  )
}
