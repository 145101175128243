import { createContext, useState, useEffect } from 'react'
import { Auth0Provider as AuthProvider, useAuth0 } from '@auth0/auth0-react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { SCREEN_HINT } from '../../utils/constants'
import { usePostLogin } from './usePostLogin'
import { AuthContextType } from './types.D'

let auth0Domain: string,
  auth0ClientId: string,
  auth0Audience: string,
  auth0RedirectUri: string = ''

if (
  process.env.BP_PUBLIC_AUTH0_DOMAIN &&
  process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID &&
  process.env.BP_PUBLIC_AUTH0_AUDIENCE
) {
  auth0Domain = process.env.BP_PUBLIC_AUTH0_DOMAIN
  auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
  auth0Audience = process.env.BP_PUBLIC_AUTH0_AUDIENCE
} else {
  // TODO: Sentry integration
  throw new Error('Auth0 domain or client id is missing in Auth Provider')
}

if (typeof window !== 'undefined') {
  auth0RedirectUri = window.location.origin
}

export const BPAuthContext = createContext<AuthContextType | object>({})

export const BPAuthProvider = ({ children }: { children: React.ReactElement }) => {
  const { loginWithRedirect, logout, user, error, isLoading, getAccessTokenSilently } = useAuth0()
  const [accessToken, setAccessToken] = useState('')
  const { status, setStatus } = usePostLogin({ user, accessToken })

  const getAccessToken = async () => {
    const token = await getAccessTokenSilently()
    setAccessToken(token)
    try {
      await AsyncStorage.setItem('token', token)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (user) {
      getAccessToken()
    }
  }, [user])

  const props = {
    login: (isSignup: boolean | undefined) => {
      let authorizationParams: { [key: string]: string | undefined } = {}
      authorizationParams.scope = 'openid profile email'

      if (isSignup) {
        authorizationParams.screen_hint = SCREEN_HINT.signup
      }

      loginWithRedirect({
        authorizationParams,
      })
    },
    logout: async () => {
      logout({ logoutParams: { returnTo: process.env.NEXT_PUBLIC_SITE_URL } })
      await AsyncStorage.removeItem('token')
      setAccessToken('')
      return true
    },
    user,
    error,
    isLoading,
    accessToken,
    status,
    setStatus,
    getAccessToken,
  }

  return <BPAuthContext.Provider value={{ ...props }}>{children}</BPAuthContext.Provider>
}

export const Auth0Provider = ({ children }: { children: React.ReactElement }) => (
  <AuthProvider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: auth0RedirectUri,
      audience: auth0Audience,
      scope: 'openid profile email',
    }}
  >
    <BPAuthProvider>{children}</BPAuthProvider>
  </AuthProvider>
)
