import AsyncStorage from '@react-native-async-storage/async-storage'

/**
 * Request internal api routes like https://blackplanet.com/api/email'
 * Note: This will not work for Pre-prod environments due to CORS issue caused by netlify site protection
 */

async function request(path, method = 'GET', query = null, body = null) {
  console.log('Request:', process.env.BP_PUBLIC_HOST_URL)
  try {
    let url = `${process.env.BP_PUBLIC_HOST_URL}${path}`
    const token = await AsyncStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
      withCredentials: true,
    }
    let options: RequestInit = { method, headers }

    if (query) {
      url += '?' + new URLSearchParams(query).toString()
    }
    if (body) {
      options.body = JSON.stringify(body)
    }

    const response = await fetch(url, options)
    const json = await response.json()
    if (response.ok) {
      return json
    } else {
      return Promise.reject({ status: response.status, ...json })
    }
  } catch (e) {
    // Network issue e.g. offline.
    return Promise.reject(e)
  }
}

const http = {
  get: (path, query?) => request(path, 'GET', query),
  post: (path, body?, query?) => request(path, 'POST', query, body),
}

export default http
