import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M20 4H4C3.4713 4.00264 2.96516 4.21452 2.59225 4.58931C2.21933 4.9641 2.00999 5.4713 2.01 6L2 18C2.00158 18.5299 2.2128 19.0377 2.58753 19.4125C2.96227 19.7872 3.47005 19.9984 4 20H20C20.5299 19.9984 21.0377 19.7872 21.4125 19.4125C21.7872 19.0377 21.9984 18.5299 22 18V6C21.9984 5.47005 21.7872 4.96227 21.4125 4.58753C21.0377 4.2128 20.5299 4.00158 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'EmailIcon'

export const EmailIcon = memo<IconProps>(themed(Icon))
