import { createStore } from 'zustand'
import { StreamChat } from 'stream-chat'
import { StreamVideoClient } from 'app/lib/stream/video'
import { useUserInfoStore } from 'app/store'
const client = StreamChat.getInstance(process.env.BP_PUBLIC_GS_KEY)
const apiKey = process.env.BP_PUBLIC_GS_KEY
const defaultProps = {
  client,
  chat: false,
  chatConnecting: false,
  chatConnected: false,
  chatConnectionError: false,
  video: false,
  videoReady: false,
  videoConnecting: false,
  videoConnected: false,
  videoConnectionError: false,
  activeCall: false,
  activeChannel: false,
  loading: false,
  rooms: false,
  joinCallError: false,
  joinChannelError: false,
  disconnected: false,
}
const store = createStore()((set, get) => ({
  ...defaultProps,
  connect: async () => {
    const userInfo = useUserInfoStore.getState()
    const userId = userInfo?.id?.toString()
    // Temporary: Tokens will be provided by api service
    const tokenRespone = await fetch(`${process.env.BP_PUBLIC_HOST_URL}/api/gs/token`, {
      body: JSON.stringify({
        userId,
      }),
      method: 'POST',
    })
    const { token } = await tokenRespone.json()
    const user = { id: userId }
    // Connect to getstream chat client
    set({ chatConnected: false, chatConnecting: true, chatConnectionError: false })
    try {
      const client = new StreamChat(apiKey)
      await client.connectUser(user, token)
      console.log('CONNECTED >>>>>>>>>>>>>>>>')

      set({ chat: client, chatConnected: true, chatConnecting: false, disconnected: false })
    } catch (error) {
      console.error('Error setting up chat client', error)
      set({ chatConnected: false, chatConnecting: false, chatConnectionError: error?.message })
    }
    // Connect to getstream video & audio client
    set({ videoConnected: false, videoConnecting: true, videoConnectionError: false })
    try {
      const client = new StreamVideoClient({ apiKey, user, token })
      set({ video: client, videoConnected: true, videoConnecting: false, disconnected: false })
    } catch (error) {
      console.error('Error setting up video client', error)
      set({ videoConnected: false, videoConnecting: false, videoConnectionError: error?.message })
    }
  },
  disconnect: async () => {
    await get().client?.disconnectUser()
    set({ ...defaultProps, disconnected: true })
  },
  joinCall: async (roomType: string, roomId: string, upsert = true) => {
    try {
      const video = get().video
      const call: any = await video.call(roomType, roomId)
      await call.join()
      set({ activeCall: call })
    } catch (error) {
      console.error('Video/Audio join error:', error)
      set({
        joinCallError: error?.response?.data?.message || 'Error joining video/audio room',
        activeCall: false,
      })
    }
  },
  joinChannel: async (roomType: string, roomId: string, upsert = true) => {
    try {
      const chat = get().chat
      const filter = { type: roomType, id: roomId }
      const sort = [{ last_message_at: -1 }]
      const channels: any[] = await chat.queryChannels(filter, sort, {
        watch: true,
        state: true,
      })
      const channel = channels[0]
      console.log('channel ', channel)
      set({ activeChannel: channel })
    } catch (error) {
      console.error('Chat join error:', error)
      set({
        joinChannelError: error?.response?.data?.message || 'Error joining chatroom',
        activeChannel: false,
      })
    }
  },
  endCall: () => {
    const call = get().activeCall
    if (call) {
      call.endCall()
    }
    set({ activeCall: false })
  },
  endChannel: () => {
    // ToDo
  },
  leaveCall: () => {
    const call = get().activeCall
    if (call) {
      call.leave().catch((error) => {
        console.error(error)
      })
    }
    set({ activeCall: false })
  },
  leaveChannel: () => {
    // ToDo
  },
  setActiveChannel: (channel) => {
    set({ activeChannel: channel })
  },
}))
export default store
