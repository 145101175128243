import React, { memo } from 'react'
import PropTypes from 'prop-types'
import type { IconProps } from '@tamagui/helpers-icon'
import {
  Svg,
  Circle as _Circle,
  Ellipse,
  G,
  LinearGradient,
  RadialGradient,
  Line,
  Path,
  Text as _Text,
} from 'react-native-svg'
import { themed } from '@tamagui/helpers-icon'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill={color} {...props}>
      <Path
        d="M17.7553 16.3461C18.7228 15.0234 19.1554 13.3843 18.9668 11.7564C18.7781 10.1285 17.982 8.6318 16.7377 7.56548C15.4933 6.49916 13.8924 5.9418 12.2549 6.00482C10.6174 6.06783 9.06401 6.74658 7.90528 7.90538C6.74654 9.06417 6.06783 10.6176 6.00482 12.2552C5.9418 13.8928 6.49914 15.4938 7.5654 16.7382C8.63166 17.9826 10.1283 18.7788 11.7561 18.9674C13.3839 19.1561 15.0229 18.7234 16.3456 17.7559L20.5911 22L22 20.5926L17.7537 16.3461H17.7553ZM12.5282 17.0168C11.6407 17.0168 10.7731 16.7536 10.0352 16.2605C9.29726 15.7674 8.72212 15.0666 8.38249 14.2466C8.04286 13.4266 7.954 12.5243 8.12714 11.6539C8.30028 10.7834 8.72765 9.98378 9.35521 9.35619C9.98276 8.72861 10.7823 8.30121 11.6528 8.12806C12.5232 7.95491 13.4254 8.04378 14.2454 8.38343C15.0653 8.72307 15.7661 9.29825 16.2592 10.0362C16.7523 10.7742 17.0154 11.6418 17.0154 12.5293C17.0141 13.7191 16.541 14.8597 15.6997 15.701C14.8585 16.5423 13.7179 17.0155 12.5282 17.0168Z"
        fill={color}
      />
      <Path
        d="M8 2C6.7048 2.00075 5.43983 2.38271 4.36971 3.09615C3.29958 3.8096 2.47386 4.82149 2 6.00015C2.47327 7.17918 3.29886 8.19142 4.36913 8.9049C4.75846 9.16444 5.17359 9.38007 5.60659 9.54939C5.81687 9.05862 6.07946 8.59036 6.39066 8.15313C5.98732 7.86488 5.67207 7.47332 5.48033 7.02068C5.27391 6.53339 5.2199 5.9972 5.32513 5.47989C5.43036 4.96259 5.69011 4.48742 6.07153 4.11446C6.45294 3.74151 6.9389 3.48752 7.46794 3.38463C7.99697 3.28173 8.54534 3.33454 9.04368 3.53638C9.54203 3.73822 9.96797 4.08003 10.2676 4.51858C10.4207 4.74251 10.5373 4.98653 10.6147 5.24195C11.1348 5.10657 11.6716 5.02652 12.2164 5.00556C12.6592 4.98852 13.0996 5.01079 13.5329 5.07071C13.0548 4.28889 12.4069 3.61393 11.6303 3.09615C10.5602 2.38271 9.2952 2.00075 8 2Z"
        fill={color}
      />
      <Path
        d="M9.15708 4.86874C9.3636 5.07067 9.50921 5.32102 9.58237 5.59249C8.6992 5.96592 7.88805 6.50836 7.19815 7.19829C7.15743 7.23902 7.11721 7.28017 7.07752 7.32173C6.99383 7.26588 6.91524 7.20229 6.84292 7.13157C6.61407 6.9078 6.45822 6.62269 6.39508 6.31231C6.33194 6.00193 6.36434 5.68021 6.4882 5.38784C6.61205 5.09546 6.82179 4.84557 7.09089 4.66975C7.35998 4.49393 7.67636 4.40009 8 4.40009C8.43399 4.40009 8.85021 4.56867 9.15708 4.86874Z"
        fill={color}
      />
    </Svg>
  )
}

Icon.displayName = 'DiscoverIcon'

export const DiscoverIcon = memo<IconProps>(themed(Icon))
